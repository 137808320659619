/* Reset and base styles */
html {
  scroll-behavior: smooth;
  background-color: black;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* App Container */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Logo Animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header Styles */
.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
  position: relative;
  z-index: 10;
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 20px;
  padding-bottom: 60px;
  margin-top: 20px;
  background-color: black;
  color: white;
  min-height: calc(100vh - 120px); /* Adjust for header and footer */
}

/* Footer Styles */
footer {
  background-color: black;
  padding: 20px;
  color: white;
  text-align: center;
  width: 100%;
  margin-top: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Button Styles */
.button {
  background-color: #353c44;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: 500;
}

.button:hover {
  background-color: #4a5561;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(74, 85, 97, 0.4);
}

.button:active {
  transform: translateY(0);
}

/* Link Styles */
.link {
  color: #c9c9c9;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}

.link:hover {
  color: #ffffff;
}

.link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

.link:hover::after {
  width: 100%;
}

/* Image Styles */
.image {
  transition: all 0.3s ease;
  border-radius: 8px;
}

.image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

/* Loading Animation */
.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }

  .button {
    padding: 10px 20px;
  }

  .App-header {
    padding: 10px;
  }
}

/* Utility Classes */
.text-gradient {
  background: linear-gradient(45deg, #c9c9c9, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Animations */
.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
  background-color: black;
}

::-webkit-scrollbar-thumb {
  background-color: #353c44;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4a5561;
}