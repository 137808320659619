.careers {
    padding: 60px 20px;
    background-color: #121212; /* Dark background */
    border-radius: 12px; /* Rounded corners */
    color: #ffffff; /* Text color */
  }
  
  .careers-header {
    text-align: center; /* Center align header text */
  }
  
  .office-image {
    width: 100%; /* Make image responsive */
    max-width: 600px; /* Limit max width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px;
  }
  
  .job-listings {
    margin-top: 40px; /* Space above job listings */
  }
  
  .job-card {
    background-color: rgb(49, 45, 45); /* Card background color */
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px; /* Space between job cards */
  }
  
  .job-card h3 {
    margin-bottom: 10px; /* Space below job title */
  }
  
  .apply-btn {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #3498db; /* Button color */
    color: white; /* Button text color */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  .apply-btn:hover {
     background-color: #2980b9; /* Darker shade on hover */
  }
  
  .careers-info {
    margin-top: 40px; /* Space above careers info section */
  }