.services-section {
    background-size: cover;
    color: black;
    padding: 100px 20px;
    text-align: center;
  }
  
  .services-section .content {
    max-width: 800px;
    margin: 0 auto;
  }
  