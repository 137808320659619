/* General Header Styling */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  padding: 10px 20px;
  background-color: rgba(30, 30, 30, 0.9); /* Dark background */
  color: #e0e0e0; /* Light text color */
  z-index: 1000;
  transition: background-color 0.3s, box-shadow 0.3s, top 0.3s ease, opacity 0.3s ease; /* Added opacity transition */
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1); /* Lighter shadow for contrast */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header.scrolled {
  background-color: rgba(40, 40, 40, 0.95); /* Slightly lighter on scroll */
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.15); /* Lighter shadow */
}

.header.hide {
  opacity: 0; /* Hide the header by reducing opacity */
  pointer-events: none; /* Disable interaction when hidden */
}

.header.show {
  opacity: 1; /* Show the header by resetting opacity */
  pointer-events: all; /* Re-enable interaction */
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo img {
  height: 80px;
}

/* Menu */
.menu ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 0 10px;
  position: relative;
}

.menu a {
  color: #e0e0e0; /* Light text color */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
  padding-bottom: 5px;
}

.menu a.active::after {
  width: 100%;
}

.menu a:hover {
  color: #007bff; /* Change color on hover */
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.mobile-menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #e0e0e0; /* Light color for bars */
  margin: 4px 0;
  transition: 0.3s;
}

.menu.open {
  display: block;
}

/* Responsive Mobile Styling */
@media (max-width: 768px) {
  .menu {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: rgba(30, 30, 30, 0.95);
    width: 100%;
    flex-direction: column;
    padding: 10px 0;
  }

  .menu.open {
    display: flex;
  }

  .menu ul {
    flex-direction: column;
    align-items: center;
  }

  .menu li {
    margin: 10px 0;
  }

  .mobile-menu-icon {
    display: flex;
  }
}
