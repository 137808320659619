body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Responsive CSS */
@media screen and (max-width: 768px) {
  .container {
    padding: 1rem;
  }
  
  .grid {
    grid-template-columns: 1fr;
  }
  
  .text-content {
    font-size: 16px;
  }
}

/* Fluid typography */
html {
  font-size: calc(14px + 0.390625vw);
}

/* Mobile optimizations */
* {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

button, 
a {
  min-height: 44px;
  min-width: 44px;
}

body {
  overflow-x: hidden;
  width: 100%;
}
