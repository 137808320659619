.footer {
  display: flex;
  justify-content: space-between; /* Distribute the three sections evenly */
  align-items: center; /* Vertically align items within each section */
  padding: 20px;
  background-color: #121212;
  color: #f0f0f0;
  height: 90px;
}

.footer-left, .footer-center, .footer-right {
  display: flex;
  align-items: center; /* Center items vertically in each section */
}

.footer-logo {
  width: 150px;
  margin-right: 20px; /* Add space between logo and text */
  height: 100px;
}

.footer-left p {
  margin: 0; /* Remove default margins */
}

.footer-center a {
  color: #f0f0f0;
  text-decoration: none;
  margin: 0 10px; /* Add horizontal spacing between links */
}

.footer-right {
  gap: 10px; /* Add space between social media icons */
}

.social-icon {
  width: 30px;
  height: 30px;
}



/* Responsive Design for smaller screens */
@media (max-width: 768px) {
  .footer {
    flex-direction: column; /* Stack sections vertically on small screens */
    text-align: center; /* Center-align content */
  }

  .footer-left, .footer-center, .footer-right {
    margin-bottom: 20px; /* Add space between sections on small screens */
  }
}
